import { Injectable } from '@angular/core';
import { BackendService } from '@services/backend.service';
import { ApiResponse } from 'app/core/types/api.type';
import { NextObjectHelper } from '@utils/core/next-object.helper';
import { map } from 'rxjs';
import { ApiProps } from 'app/core/enums/api.enum';
import { CountryEditProps, CountryProps, DistributionBodiesProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { CountryEndpoints } from '../constant/country.endpoints.constant';
import { Country } from '../types/country.type';
import { FormBuilder } from '@angular/forms';
import { RiskRatings } from '../types/risk-ratings.type';
import { NextFormHelper } from '@utils/core/next-form.helper';
import { FormConfig } from '@utils/models/Form';
import { FormConfigProps } from '@utils/enums/forms.enum';
import { ToastMsgService } from '@services/toast-msg.service';
import { NavigationConfigProps } from 'app/shared/enums/navigation.enum';
import { DropdownService } from '@components/atoms/form-inputs/components/next-dropdown/services/dropdown.service';
import { NextValueHelper } from '@utils/core/next-value.helper';
import { MsgProps } from 'app/shared/enums/backend.enum';
import { RouteParams } from 'app/core/enums/route.enum';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    constructor(
        private _backendService: BackendService,
        private _toastService: ToastMsgService,
        private _formBuilder: FormBuilder,
        private _dropdownService: DropdownService) {}

    getCountries() {
        return this._backendService.get(CountryEndpoints.config.LIST).pipe(
            map((response: ApiResponse<Country>) => {
              return NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.COUNTRIES], []);
        }))
    }

    getRiskRatingCountries() {
        return this._backendService.get(CountryEndpoints.config.RISK_RATING).pipe(
            map((response: ApiResponse<RiskRatings>) => {
              return NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.ALL_RATINGS], []);
        }))
    }

    createDropdownRiskRatingList(result: any) {
        const listRiskRating = NextValueHelper.defaultValue(result, []);
        const countriesRiskRating = new Map();

        listRiskRating.map((riskRating: any) => {
            countriesRiskRating.set(riskRating.riskRatingCriteria, this._dropdownService.getDropdownItem(riskRating.riskRatingCriteria,riskRating.riskRatingCriteria));
        });
        return Array.from(countriesRiskRating.values());
    }

    updateCountry(id: string, form: any, selectedCountry: any) {
        const maintenanceTask = NextObjectHelper.getPropertyFromObject(selectedCountry, [CountryProps.TASK]);
        let config = CountryEndpoints.config.EDIT_COUNTRY;
        const urlParams: any = { [RouteParams.ID]: id };
        delete form.code;
        if (maintenanceTask) {
            form.maintenanceTaskId = maintenanceTask.id;
            return this._backendService.put(config, form,{
                urlParams: urlParams
              }).pipe(
                map((response: ApiResponse<any>) => {
                  const country = NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.TASK]);
                    if(country) {
                      this.handleSaveSuccess();
                      return {...country}
                    }
                })
            );
        } else {
            return this._backendService.post(config, form,{
                urlParams: urlParams
              }).pipe(
                map((response: ApiResponse<any>) => {
                  const country = NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.TASK]);
                    if(country) {
                      this.handleSaveSuccess();
                      return {...country}
                    }
                })
            );
        }
    }

    getCountryById(id: string) {
        return this._backendService.get(CountryEndpoints.config.DETAILS,{
            [NavigationConfigProps.URL_PARAMS]: {id}
          }).pipe(
            map((response: ApiResponse<Country>) => {
              return NextObjectHelper.getPropertyFromObject(response, [ApiProps.RESULT,DistributionBodiesProps.COUNTRY], []);
        }))
    }

    buildCountryEditPopUp(country: Country) {
        const { props, config } = this._buildCountryConfigValues(country);
        const groupValue = NextFormHelper.buildGroupValuesWithConfig(props, country, config);

        return this._formBuilder.group(groupValue);
    }

    protected handleSaveSuccess() {
        this._showSuccessMsg();
      }

      private _showSuccessMsg() {
        this._toastService.success('API.SAVE_SUCCESS', {[MsgProps.ENTITY]: [MsgProps.SECTION_COUNTRY]});
      }

    private _buildCountryConfigValues(country: Country) {
        const props = [
            CountryEditProps.CODE,
            CountryEditProps.RISK,
            CountryEditProps.INSURANCE_EXCEPTION_AMOUNT,
            CountryEditProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP,
            CountryEditProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP
        ];
        const config: FormConfig = {
            [CountryEditProps.RISK]:{
                [FormConfigProps.DEFAULT_VALUE]: country.riskCode,
                [FormConfigProps.IS_REQUIRED]: true
            },
            [CountryEditProps.INSURANCE_EXCEPTION_AMOUNT]:{
                [FormConfigProps.DEFAULT_VALUE]: country.insuranceExceptionAmount,
                [FormConfigProps.IS_REQUIRED]: true
            },
            [CountryEditProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP]:{
                [FormConfigProps.DEFAULT_VALUE]: country.insuranceExceptionEffectiveTimestamp,
                [FormConfigProps.IS_REQUIRED]: true
            },
            [CountryEditProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP]:{
                [FormConfigProps.DEFAULT_VALUE]: country.insuranceExceptionExpiryTimestamp,
                [FormConfigProps.IS_REQUIRED]: true
            }
        }
        return { props, config};
    }
}
