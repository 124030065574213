<next-dialog [visible]="isOpen"
    [titleKey]="titleKey | translate" 
    [submitLabelKey]="'BUTTONS.MODIFY_AND_SEND'"
    [hideDismiss]="hideDismiss" 
    [dialogSize]="dialogSize"
    [isSubmitDisabled]="formGroup.invalid || formGroup.pristine"
    (cancelClick)="dismissModal()"
    (submitClick)="handleSubmit()"
    (visibleChange)="dismissModal()">
    <div class="details-dialog__content" dialogContent>
        <input-container
            *ngIf="formGroup | deepControl: [CountryEditProps.RISK] as riskRatingGroupControl"
            [control]="riskRatingGroupControl" [labelKey]="'COUNTRY.FIELDS.RISK_RATING'"
            class="country-filter__item">
            <next-dropdown [control]="riskRatingGroupControl" [items]="riskRatingDropdown" [filter]="false" [name]="CountryEditProps.RISK" [placeholder]="'COUNTRY.FILTER.SEARCH_RATING'" [testIdDataAttr]="CountryEditProps.RISK" inputElement></next-dropdown>
        </input-container>
        <input-container *ngIf="formGroup | deepControl: [CountryEditProps.INSURANCE_EXCEPTION_AMOUNT] as countryInExcepAmountControl"
                   [control]="countryInExcepAmountControl"
                   [labelKey]="'COUNTRY.FIELDS.EXCEPTION_FDI_LIMITS'"
                   >
            <next-input-number [control]="countryInExcepAmountControl" [type]="InputNumberType.AMOUNT" [testIdDataAttr]="CountryEditProps.INSURANCE_EXCEPTION_AMOUNT" inputElement></next-input-number>
        </input-container>
        <input-container *ngIf="formGroup | deepControl: [CountryEditProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP] as countryInExcepEffectivDateControl"
                   [control]="countryInExcepEffectivDateControl"
                   [labelKey]="'COUNTRY.FIELDS.EXCEPTION_EFFECTIVE_DATE'" slot1>

            <next-calendar [control]="countryInExcepEffectivDateControl" [testIdDataAttr]="CountryEditProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP"
                        inputElement></next-calendar>
        </input-container>
        <input-container *ngIf="formGroup | deepControl: [CountryEditProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP] as countryInExcepExpiryDateControl"
                        [control]="countryInExcepExpiryDateControl"
                        [labelKey]="'COUNTRY.FIELDS.EXCEPTION_EXPIRY_DATE'" slot1>

            <next-calendar [control]="countryInExcepExpiryDateControl" [testIdDataAttr]="CountryEditProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP"
                        inputElement></next-calendar>
        </input-container>
    </div>
</next-dialog>