import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputNumberType } from '@components/atoms/form-inputs/enums/input.enum';
import { DialogSize } from '@components/organisms/next-dialog/next-dialog.enum';
import { CountryEditProps, CountryProps } from '@pages/general-maintenance/pages/country/enums/country.enum';
import { CountryService } from '@pages/general-maintenance/pages/country/services/country.service';
import { Country } from '@pages/general-maintenance/pages/country/types/country.type';

@Component({
  selector: 'country-edit-popup',
  templateUrl: './country-edit-popup.component.html',
  styleUrl: './country-edit-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryEditPopupComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() selectedCountry: Country;
  @Input() riskRatingDropdown: any;
  @Input() titleKey: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitCountry: EventEmitter<any> = new EventEmitter<any>();

  hideDismiss: boolean = true;
  hideCancelButton: boolean = true;
  dialogSize: DialogSize = DialogSize.MEDIUM;
  formGroup: FormGroup;
  
  protected readonly CountryProps = CountryProps;
  protected readonly CountryEditProps = CountryEditProps;
  protected readonly InputNumberType = InputNumberType;
  
  constructor(private _countryService: CountryService) {}
  
  ngOnInit() {
    this._defineFormGroup();
  }

  dismissModal() {
    this.cancel.emit();
  }

  handleSubmit() {
    this.submitCountry.emit({form: this.formGroup.getRawValue(), selectedCountry: this.selectedCountry});
    this.isOpen = false;
  }
  
  private _defineFormGroup() {
    this.formGroup = this._countryService.buildCountryEditPopUp(this.selectedCountry);
  }
  
}
