<next-action-header
[labelKey]="title">
    <ng-container *ngIf="isTaskBoard" actionContent>
        <review-modified-fields *ngIf="dataCountry"
        [updateData]="dataCountry"
        [originalData]="dataCountryRef"
        [dataMapper]="CountryConstant.compareDataMapper"
        [section]="section"
        [idTask]="idTask"
        ></review-modified-fields>
    </ng-container>
</next-action-header>

<div *ngIf="isTaskBoard; else dataCountries">
    <general-details *ngIf="dataCountry"
        [header]="('COUNTRY.FIELDS.ID_COUNTRY') | translate: {countryCode}"
        [data]="dataCountry"
        [dataRef]="dataCountryRef"
        [dataTitleConfig]="CountryConstant.countryDetailsMap"
        [status]="dataCountry | safeGuardObjectProperty: [CountryProps.STATUS]"
    ></general-details>
</div>
<ng-template #dataCountries>
    <country-filter *ngIf="countriesList"
        [filterValues]="filterParams"
        (filterChange)="handleFilterChange($event)"
        [countriesList]="countriesList"
        [riskRatingList]="riskRatingList"
        [countriesRiskRatingDropdown]="countriesRiskRatingDropdown"
        [titleButtonCriteria]="(titleButtonCriteria) | translate"
        [titleCriteria]="(titleCriteria) | translate"
        [iconPositionButtonInfo]="iconPositionButtonInfo"
        [hasExtraFilter]="hasExtraFilter"
        ></country-filter>

    <next-table *ngIf="countriesList"
        [data]="countriesList"
        [dataKey]="dataKey"
        [columns]="columnsTableCountries"
        [filterValues]="filterParams"
        [globalFilterFields]="globalFilterFields"
        (pageChange)="pageChangeHandle($event)"
        [sortField]="sortField"
        [actionIconType]="actionIconType"
        (clickViewActionEvt)="viewPopup($event)"
        [selectedRows]="selectedCountry"
        (selectionChange)="selectedCountry = $event"
        [showDownloadButton]="true">
    </next-table>

    <country-edit-popup *ngIf="activePopup" [isOpen]="activePopup"
                        [selectedCountry]="selectedCountry"
                        [riskRatingDropdown]="countriesRiskRatingDropdown"
                        [titleKey]="('COUNTRY.FIELDS.EDIT_COUNTRY') | translate: {nameCountry: selectedCountry.name}"
                        (cancel)="dismissPopup()"
                        (submitCountry)="updateCountry($event)"></country-edit-popup>
</ng-template>
