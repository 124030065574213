<next-table-filter
    [filterValues]="filterValues"
    (filterChange)="filterChange.emit($event)"
    [placeholderInputSearch]="'COUNTRY.FILTER.SEARCH_NAME'"
    [extraFormGroup]="extraFormGroup"
    [extraFormGroupInitialValues]="CountryConstant.extraFilterInitialValues"
    [hasExtraFilter]="hasExtraFilter">

    <div class="country-filter__form" extraFilterContent>
         <input-container
            *ngIf="extraFormGroup | deepControl: [CountryFilterProps.CODE] as countryCodeGroupControl"
            [control]="countryCodeGroupControl" [labelKey]="'COUNTRY.FIELDS.COUNTRY_CODE'"
            class="country-filter__item">
            <next-dropdown [control]="countryCodeGroupControl" [items]="countriesCodeDropdown" [name]="CountryFilterProps.CODE" [placeholder]="'COUNTRY.FILTER.SEARCH_COUNTRY_CODE'" [testIdDataAttr]="CountryFilterProps.CODE" inputElement></next-dropdown>
        </input-container>
        <input-container
            *ngIf="extraFormGroup | deepControl: [CountryFilterProps.OPERATIONAL_REGION] as regionGroupControl"
            [control]="regionGroupControl" [labelKey]="'COUNTRY.FIELDS.REGION'"
            class="country-filter__item">
            <next-dropdown [control]="regionGroupControl" [items]="countriesRegionsDropdown" [name]="CountryFilterProps.OPERATIONAL_REGION" [placeholder]="'COUNTRY.FILTER.SEARCH_REGION'" [testIdDataAttr]="CountryFilterProps.OPERATIONAL_REGION" inputElement></next-dropdown>
        </input-container>
        <input-container
            *ngIf="extraFormGroup | deepControl: [CountryFilterProps.EURO_INDICATOR] as EUMemberGroupControl"
            [control]="EUMemberGroupControl" [labelKey]="'COUNTRY.FIELDS.EU_MEMBER'"
            class="country-filter__item">
            <next-dropdown [control]="EUMemberGroupControl" [items]="countriesEuMemberDropdown" [filter]="false" [name]="CountryFilterProps.EURO_INDICATOR" [placeholder]="'COUNTRY.FILTER.SEARCH_EU_MEMBER'" [testIdDataAttr]="CountryFilterProps.EURO_INDICATOR" inputElement></next-dropdown>
        </input-container>
        <input-container
            *ngIf="extraFormGroup | deepControl: [CountryFilterProps.FINISH_VEHICLE_ACCOUNTING] as insuredFDIGroupControl"
            [control]="insuredFDIGroupControl" [labelKey]="'COUNTRY.FIELDS.INSURED_FDI'"
            class="country-filter__item">
            <next-dropdown [control]="insuredFDIGroupControl" [items]="countriesInsuredFDIDropdown" [filter]="false" [name]="CountryFilterProps.FINISH_VEHICLE_ACCOUNTING" [placeholder]="'COUNTRY.FILTER.SEARCH_INSURED_FDI'" [testIdDataAttr]="CountryFilterProps.FINISH_VEHICLE_ACCOUNTING" inputElement></next-dropdown>
        </input-container>
        <input-container
            *ngIf="extraFormGroup | deepControl: [CountryFilterProps.RISK_CODE] as riskRatingGroupControl"
            [control]="riskRatingGroupControl" [labelKey]="'COUNTRY.FIELDS.RISK_RATING'"
            class="country-filter__item">
            <next-dropdown [control]="riskRatingGroupControl" [items]="countriesRiskRatingDropdown" [filter]="false" [name]="CountryFilterProps.RISK_CODE" [placeholder]="'COUNTRY.FILTER.SEARCH_RATING'" [testIdDataAttr]="CountryFilterProps.RISK_CODE" inputElement></next-dropdown>
        </input-container>
    </div> 
    <div class="country-filter__form" extraFilterContent>
        <input-container *ngIf="extraFormGroup" [labelKey]="'COUNTRY.FIELDS.EXCEPTION_EFFECTIVE_DATE'">
            <date-from-to [fromControl]="extraFormGroup | deepControl: [CountryFilterProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_FROM]"
            [toControl]="extraFormGroup | deepControl: [CountryFilterProps.INSURANCE_EXCEPTION_EFFECTIVE_TIMESTAMP_TO]" [styleClass]="'country-filter__calendar'" inputElement></date-from-to>
        </input-container>
        <input-container *ngIf="extraFormGroup" [labelKey]="'COUNTRY.FIELDS.EXCEPTION_EXPIRY_DATE'">
            <date-from-to [fromControl]="extraFormGroup | deepControl: [CountryFilterProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_FROM]"
            [toControl]="extraFormGroup | deepControl: [CountryFilterProps.INSURANCE_EXCEPTION_EXPIRY_TIMESTAMP_TO]" [styleClass]="'country-filter__calendar'" inputElement></date-from-to>
        </input-container>
    </div>

    <div panelCriteriaContent>
        <next-overlay-panel
            [title]="'COUNTRY.FIELDS.RISK_RATING_CRITERIA_AND_FDI_COUNTRY_LIMIT' | translate"
            [data]="riskRatingList"
            [colorTypeMap]="RiskRatingsConstant.colorRiskRatingCriteria"
            [ColorTypeProp]="RiskRatingsProps.RISK_RATING_CRITERIA"
            [labelButton]="'COUNTRY.FIELDS.VIEW_RISK_RATING_CRITERIA' | translate"
            [config]="configPanelRiskRating"></next-overlay-panel>
    </div>
</next-table-filter>